import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import cn from 'classnames';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { JsonEditor } from 'json-edit-react';

import { errorService } from 'services/alert/services';
import { StaticBlockService } from 'services/api/static-block';

import { useStyles } from './styles';

const CreateOrUpdateStaticBlockModal = ({ name, open, handleClose, onSuccess }) => {
  const styles = useStyles();

  const [data, setData] = useState<Record<string, any> | null>(null);

  useEffect(() => {
    (async () => {
      if (!name) return;
      try {
        const data = await StaticBlockService.getByName(name);
        setData(data.staticBlock);
      } catch (e) {
        errorService.sendError(e.message);
      }
    })();
  }, [name, open]);

  const handleSubmit = async (values) => {
    try {
      if (name) await StaticBlockService.update(data?._id, values.name, values.data);
      else await StaticBlockService.create(values.name, values.data);
      onSuccess();
    } catch (e) {
      errorService.sendError(e.message);
    } finally {
      onClose();
    }
  };

  const initialValues = useMemo(() => {
    return {
      name: data?.name ?? '',
      data: data?.data ?? {},
    };
  }, [data]);

  const onClose = () => {
    setData(null);
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        className={styles.dialog}
        PaperProps={{ className: styles.paper }}
      >
        <DialogTitle>{name ? 'Обновить' : 'Создать'} CMS блок</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Введите название'),
            })}
            validateOnChange
            onSubmit={handleSubmit}
            render={({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              touched,
              values,
              errors,
            }) => {
              return (
                <form onSubmit={handleSubmit} className={styles.form}>
                  <FormControl className={styles.formControl} size="small">
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.name && touched.name)}
                      name="name"
                      margin="dense"
                      label="Название"
                      value={values.name}
                      placeholder="Введите название"
                    />
                    <div className={styles.errorText}>
                      {touched.name ? (errors.name as string) : ''}
                    </div>
                  </FormControl>
                  <FormControl className={styles.formControl} size="small">
                    <JsonEditor
                      data={values.data}
                      theme="githubDark"
                      setData={(v) => {
                        setFieldValue('data', v);
                      }}
                    />
                  </FormControl>
                  <DialogActions>
                    <Button color="primary" variant="contained" type="submit">
                      {name ? 'Обновить' : 'Создать'}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateOrUpdateStaticBlockModal;
